<!-- 分类管理 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="container"
  >
    <div class="hd">
      <div class="name">
        <span>问答分类</span>
        <el-button
          @click="addVisible = true"
          type="primary"
          class="common-screen-btn"
          >新增分类</el-button
        >
      </div>
      <div class="gray"></div>
      <div class="common-screen-container">
        <div class="common-input-container">
          <span>分类名称:</span>
          <el-input
            class="common-screen-input"
            v-model="typeName"
            placeholder="请输入"
          ></el-input>
          <el-button class="common-screen-btn" type="primary" @click="commonFun"
            >查 询</el-button
          >
          <el-button class="common-screen-btn" @click="() => resetBtn()" plain
            >重 置</el-button
          >
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="padding">
      <el-table
        :data="tableData"
        style="width: 100%"
        class="common-table common-table-bgGrey"
      >
        <el-table-column prop="typeName" label="分类名称"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
            <el-button @click="del(scope.row)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      v-dialogDrag
      :title="type=='edit'? '编辑分类':'新增分类'"
      :append-to-body="true"
      :visible.sync="addVisible"
      width="560px"
      :before-close="addHandleClose"
    >
      <div style="display: flex;">
        <div>分类名称：</div>
        <el-input placeholder="请输入内容" v-model="editName" class="iptarea">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="addVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="requestAdd()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { listNew, addNew, updateNew } from "../../service/auto";

export default {
  name: "autoManage",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      typeName: "",
      tableData: [],
      editName: "",
      addVisible: false,
      type: "",
      tableRow: {}
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getTableData() {
      this.loading = true;
      const { data } = await listNew({ typeName: this.typeName });
      this.loading = false;
      this.tableData = data;
    },
    // 查询数据
    commonFun() {
      this.getTableData();
    },
    // 重置
    resetBtn() {
      this.typeName = "";
      this.getTableData();
    },
    async edit(row) {
      this.type = "edit";
      this.tableRow = row;
      this.editName = row.typeName
      this.addVisible = true;
    },
    async requestAdd() {
      if (this.type != "edit") {
        const res = await addNew({typeName: this.editName});
        if (res.code == 200) {
          this.$message.success("新增成功");
        }
      } else {
        const res = await updateNew({
          id: this.tableRow.id,
          typeName: this.editName
        });
        if (res.code == 200) {
          this.$message.success("修改成功");
        }
      }
      this.getTableData();
      this.addHandleClose();
    },
    addHandleClose() {
      this.editName = "";
      this.addVisible = false;
    },
    async del(row) {
      const res = await updateNew({ id: row.id, isDelete: "1" });
      if (res.code == 200) {
        this.$message.success("删除成功");
      }
      this.getTableData();
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  .padding {
    padding: 16px 32px;
  }
  .hd {
    height: 153px;
    .name {
      display: flex;
      justify-content: space-between;
      color: rgba(16, 16, 16, 100);
      font-size: 24px;
      padding: 18px 32px;
      span {
        font-size: 24px;
      }
      .addBtn {
        border-color: #1890ff;
        color: #1890ff;
      }
    }
    .gray {
      height: 1px;
      background-color: rgba(230, 230, 230, 100);
      margin-bottom: 24px;
    }
    .common-screen-container {
      padding-left: 32px;
    }
  }
  .line {
    height: 16px;
    background-color: #ededed;
  }
}
/deep/.iptarea {
  width: 85% !important;
}
</style>
